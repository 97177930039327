export default function useMassiveCrypto() {
  useHead({
    link: [
      { href: 'https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/assets/public/css/style.css', rel: 'stylesheet' },
      { href: 'https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/assets/public/css/selectize.custom.css', rel: 'stylesheet' },
      { href: 'https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/assets/public/css/jquery.dataTables.min.css', rel: 'stylesheet' },
    ],
  })

  const { $script } = useScript('https://wp.bitcoin.pl/wp-includes/js/jquery/jquery.min.js', {
    bundle: true,
  })

  $script.then(() => {
    useScript('https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/assets/public/js/socket.io.js', {
      bundle: true,
    })
    useScript('https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/assets/public/js/common.min.js', {
      bundle: true,
    })
    useScript('https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/assets/public/js/selectize.min.js', {
      bundle: true,
    })
  })

  onMounted(() => {
    window.mcw = {
      url: 'https://wp.bitcoin.pl/wp-content/plugins/massive-cryptocurrency-widgets/',
      ajax_url: 'https://wp.bitcoin.pl/wp-admin/admin-ajax.php',
      currency_format: {
        USD: { iso: 'USD', symbol: '$', position: '{symbol}{space}{price}', thousands_sep: ',', decimals_sep: '.', decimals: '2' },
        EUR: { iso: 'EUR', symbol: '\u20AC', position: '{price}{space}{symbol}', thousands_sep: '.', decimals_sep: ',', decimals: '2' },
        PLN: { iso: 'PLN', symbol: 'PLN', position: '{price}{space}{symbol}', thousands_sep: ',', decimals_sep: '.', decimals: '2' },
      },
      default_currency_format: { iso: 'PLN', symbol: 'PLN', position: '{symbol}{price}', thousands_sep: ',', decimals_sep: '.', decimals: '2' },
      text: { previous: 'Previous', next: 'Next', lengthmenu: 'Coins per page: _MENU_' },
    }
  })
}
